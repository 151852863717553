import React from 'react';
import './Offline.scss';

function Offline() {
  return (
    <div className="Offline">
      <div className="bx--grid">
        <div className="bx--row">
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>Coming Soon..</p>
        </div>
      </div>
    </div>
  );
}

export default Offline;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Offline from './Offline.js';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Offline />} />
        <Route path="/develop/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;

// import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Accordion, AccordionItem } from 'carbon-components-react';
import { isBrowser } from 'react-device-detect';

import './Home.scss';

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: isBrowser,
    autoplay: true,
    autoplaySpeed: 8500
  };

  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year + 1}-02-17`) - +new Date();
    let timeLeft = {};
    let minutes = Math.floor((difference / 1000 / 60) % 60);
    if (minutes === 0) {
      minutes = '00';
    }
    let seconds = Math.floor((difference / 1000) % 60);
    if (seconds === 0) {
      seconds = '00';
    }
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        mins: minutes,
        secs: seconds
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        <strong>{('00' + timeLeft[interval]).slice(-2)}</strong>
        {interval}{' '}
      </span>
    );
  });

  return (
    <div className="Home">
      <div className="bx--grid">
        <div className="bx--row top-header ">
          <div className="bx--col-lg-9">
            <header className="App-header">
              <h1 className="logo">PM DRESSUP</h1>
              <h2>A NFT Collection for Canadian Civil Liberties Charity</h2>
            </header>
          </div>
          <div className="bx--col-lg-7 col-countdown-right">
            <h2>Countdown to charity auction</h2>
            <div id="countdown">
              {timerComponents.length ? (
                timerComponents
              ) : (
                <span>Time's up!</span>
              )}
            </div>
          </div>
        </div>
        <div className="bx--row slider-row">
          <div className="bx--col-xlg-6 bx--col-lg-7 bx--col-md-4 slider-hold">
            <img
              src="/images/dressing-room-REVISED-MAY-9-2.png"
              className="slider-bg"
              alt="dressing room art"
            />
            <img
              src="/images/slider-bg-mobile.png"
              className="slider-bg-mobile"
              alt="dressing room art"
            />
            <Slider {...settings} className="">
              <div>
                <img src="/images/previews/sketch-00.jpg" alt="" />
                <div className="bottomSlides">
                  <p>Base Character</p>
                </div>
                <div className="edition first">
                  <em>(Costume: 01/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-02.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Boxer"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 02/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-03.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Graduate"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 03/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-04.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The General"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 04/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-01.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Firefighter"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 05/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-05.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Cowboy"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 06/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-06.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Pirate"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 07/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-07.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Soul Singer"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 08/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-08.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Superhero"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 09/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-09.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Taoist"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 10/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-10.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Hinduist"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 11/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-11.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Jamaican"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 12/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-12.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Chief"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 13/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-13.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Zoologist"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 14/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-14.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Musketeer"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 15/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-15.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Hindu"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 16/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-16.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Buddhist"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 17/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-17.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Arabian"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 18/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-18.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Arabian 2"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 19/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-19.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Judaist"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 20/23)</em>
                </div>
              </div>

              <div>
                <img src="/images/previews/sketch-20.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Builder"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 21/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-21.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Actor"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 22/23)</em>
                </div>
              </div>
              <div>
                <img src="/images/previews/sketch-22.jpg" alt="" />
                <div className="bottomSlides">
                  <p>"The Detective"</p>
                </div>
                <div className="edition">
                  <em>(Costume: 23/23)</em>
                </div>
              </div>
            </Slider>
          </div>
          <div className="bx--col-xlg-10 bx--col-lg-9 bx--col-md-4 slider-text">
            <div className="text-wrapper">
              <h2>Canada's Most Prolific Actor</h2>
              <p>
                <span>
                  <span>
                    An NFT Collection Inspired by Canada's 23rd Prime Minister
                    Justin Trudeau which commemorates his most memorable
                    costumes.
                  </span>
                </span>
              </p>
              <p>
                <span>
                  Our comedic collection consists of the main character,
                  Canadian Prime Minister Justin Trudeau, wearing 23 different
                  costumes and 10 variations of each. Only 200 one-of-a-kind PM
                  Dressup NFTs have been minted on the Ethereum blockchain.
                </span>
              </p>
              <p>
                <span>
                  <span>
                    These eye-catching and iconic illustrations are based on{' '}
                    <a
                      href="https://www.macleans.ca/multimedia/photo/the-mr-dressup-prime-minister/"
                      target="_blank"
                      rel="noreferrer">
                      actual costumes
                    </a>{' '}
                    that Justin Trudeau has been photographed wearing throughout
                    the years.
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="bx--row row-roadmap">
          <div className="bx--col">
            <h1>ROADMAP 1.0</h1>
          </div>
        </div>

        <div className="bx--row row-meet-team">
          <div className="bx--col">
            <h1>MEET THE CREATORS</h1>
          </div>
        </div>

        <div className="bx--row row-faqs">
          <div className="bx--col-lg-2"></div>
          <div className="bx--col-lg-12">
            <h1>FAQS</h1>
            <Accordion>
              <AccordionItem title="Question 1">
                <p>Answer goes here</p>
              </AccordionItem>
              <AccordionItem title="Question 2">
                <p>Answer goes here</p>
              </AccordionItem>
              <AccordionItem title="Question 3">
                <p>Answer goes here</p>
              </AccordionItem>
              <AccordionItem title="Question 4">
                <p>Answer goes here</p>
              </AccordionItem>
              <AccordionItem title="Question 5">
                <p>Answer goes here</p>
              </AccordionItem>
              <AccordionItem title="Question 6">
                <p>Answer goes here</p>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="bx--col-lg-2"></div>
        </div>

        <div className="bx--row bottom-footer">
          <div className="bx--col">
            <footer className="App-footer">
              <h2>Footer</h2>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
